
  import { defineComponent, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import * as Yup from "yup";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { useI18n } from "vue-i18n";
  import { UpdateUserModel } from "@/domain/user/updateUser/model/UpdateUserModel";
  import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  
  export default defineComponent({
    name: "createCompany",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();
      const userController = store.state.ControllersModule.userController;
      const swalNotification = new SwalNotification();
  
      const submitButton = ref<HTMLElement | null>(null);
      const updateUserModel = ref<UpdateUserModel>({});
  
      //Create form validation object
      const infoForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
        company: Yup.string()
          .required(t("validators_error.required"))
          .label("Company"),
      });
  
      //Form submit function
      const onSubmit = (values) => {
        // Activate loading indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
  
        userController
          .updateUser(updateUserModel.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");
  
              router.push({ name: "dashboard" });
              /*
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "dashboard" });
              });
              */
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      return {
        onSubmit,
        infoForm,
        submitButton,
        updateUserModel,
      };
    },
  });
  