<template>
    <!--begin::Wrapper-->
    <div class="w-lg-700px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
      <!--begin::Form-->
      <Form
        class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        @submit="onSubmit"
        id="kt_login_password_reset_form"
        :validation-schema="infoForm"
      >
        <!--begin::Heading-->
        <div class="text-center mb-10">
          <!--begin::Title-->
          <h1 class="text-dark mb-3">{{ $t("createCompany.title") }}</h1>
          <!--end::Title-->
  
          <!--begin::Link-->
          <div class="text-gray-400 fw-bold fs-4">
            {{ $t("createCompany.desc") }}
          </div>
          <!--end::Link-->
        </div>
        <!--begin::Heading-->
  
        <!--begin::Input group-->
        <div class="fv-row mb-10">
          <div class="row">
              <label class="form-label fw-bolder text-gray-900 fs-6 required">{{
                $t("createCompany.companyName")
              }}</label>
              <Field
                class="form-control form-control-solid"
                type="text"
                v-model="updateUserModel.name"
                :placeholder="$t('createCompany.enterCompanyName')"
                name="name"
                autocomplete="off"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
          </div>
        </div>
        <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-10">
          <div class="row">
              <label class="form-label fw-bolder text-gray-900 fs-6 required">{{
                $t("createCompany.companyDesc")
              }}</label>
              <Field
                class="form-control form-control-solid"
                type="text"
                v-model="updateUserModel.surname"
                :placeholder="$t('createCompany.enterCompanyDesc')"
                name="surname"
                autocomplete="off"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="surname" />
                </div>
              </div>
            </div>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="fv-row mb-10">
          <div class="row"> <label class="form-label fw-bolder text-gray-900 fs-6 required">{{
                   $t("createCompany.sector")
                }}</label>
                <Field
                  class="form-control form-control-solid"
                  type="text"
                  v-model="updateUserModel.tenantName"
                  :placeholder="$t('createCompany.enterSector')"
                  name="company"
                  autocomplete="off"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="company" />
                  </div>
                </div>
          </div>
        </div>
        <!--end::Input group-->
  
        <!--begin::Actions-->
        <div class="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            ref="submitButton"
            id="kt_password_reset_submit"
            class="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span class="indicator-label"> {{ $t("common.button.create") }} </span>
            <span class="indicator-progress">
              {{ $t("common.pleaseWait") }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Wrapper-->
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import * as Yup from "yup";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { useI18n } from "vue-i18n";
  import { UpdateUserModel } from "@/domain/user/updateUser/model/UpdateUserModel";
  import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  
  export default defineComponent({
    name: "createCompany",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();
      const userController = store.state.ControllersModule.userController;
      const swalNotification = new SwalNotification();
  
      const submitButton = ref<HTMLElement | null>(null);
      const updateUserModel = ref<UpdateUserModel>({});
  
      //Create form validation object
      const infoForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
        company: Yup.string()
          .required(t("validators_error.required"))
          .label("Company"),
      });
  
      //Form submit function
      const onSubmit = (values) => {
        // Activate loading indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
  
        userController
          .updateUser(updateUserModel.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");
  
              router.push({ name: "dashboard" });
              /*
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "dashboard" });
              });
              */
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      return {
        onSubmit,
        infoForm,
        submitButton,
        updateUserModel,
      };
    },
  });
  </script>
  
  